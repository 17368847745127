/* latin-ext */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 300;
  src: local('Signika Light'), local('Signika-Light'), url(../theme/fonts/Z-FsjIS3sPD5Zt6xiv44RyEAvth_LlrfE80CYdSH47w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 300;
  src: local('Signika Light'), local('Signika-Light'), url(../theme/fonts/koFYE26bhT88HgRA8f4vbfk_vArhqVIZ0nv9q090hN8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* latin-ext */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 400;
  src: local('Signika Regular'), local('Signika-Regular'), url(../theme/fonts/AF4iYPZnDjGMiNsxxSXYQfY6323mHUZFJMgTvxaG2iE.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 400;
  src: local('Signika Regular'), local('Signika-Regular'), url(../theme/fonts/q41y_9MUP_N8ipOH4ORRvw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* latin-ext */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 600;
  src: local('Signika SemiBold'), local('Signika-SemiBold'), url(../theme/fonts/8-PQNacfI9IhpgehJGCuGCEAvth_LlrfE80CYdSH47w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 600;
  src: local('Signika SemiBold'), local('Signika-SemiBold'), url(../theme/fonts/FZa_MODmDkR9DTu5jHSnSfk_vArhqVIZ0nv9q090hN8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* latin-ext */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 700;
  src: local('Signika Bold'), local('Signika-Bold'), url(../theme/fonts/F587cG5P5ff3TX6w4JloryEAvth_LlrfE80CYdSH47w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 700;
  src: local('Signika Bold'), local('Signika-Bold'), url(../theme/fonts/7M5kxD4eGxuhgFaIk95pBfk_vArhqVIZ0nv9q090hN8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* body {
  background: #f1a328;
} */

@media (min-width: 990px) {
  body {
    background:#555;
  }
  ion-app.ion-page {
    left:10%;
    right:10%
  }
  .split-pane-side {
    min-width: 0;
  }
}

@media (min-width: 1280px) {
  ion-app.ion-page {
    left:20%;
    right:20%
  }
}

html, body {
  font-family: 'Signika';
  /* font-family: var(--ion-font-family); */
}

body.scan, body.scan ion-page, body.scan ion-content {
  background:transparent;
  --ion-background-color:transparent;
}
@media (prefers-color-scheme: dark) {
  body.scan, body.scan ion-page, body.scan ion-content {
    background:transparent;
    --ion-background-color:transparent;
  }
}

body.scan #parkMapCont, body.scan ion-header {
  display:none;
}

ion-header ion-button::part(native),
ion-header ion-menu-button::part(icon),
ion-header ion-back-button::part(icon) {
  color:#fff;
}

h1:first-letter {
  text-transform: uppercase;
}

p {
  line-height: 160%;
}
ul.upperLatin {
  list-style:upper-latin;
}
.opty-padding-page {
  padding:12px;
}
.dn {
  display:none;
}


#offlinePage {
  z-index:-1;
}
#offlinePage.isOffline {
  z-index:9999;
}
#offline {
  position:absolute; z-index:9999;
  top:0; bottom:0; left:0; right:0;
  background:rgba(0,0,0,0.9);
  background:var(--ion-color-primary,0,0,0,0.9);
  /* background:url("../theme/drawable-land-hdpi-screen.png") top center no-repeat #457600; */
  color:#fff;
}
#offline h3 {
  position:absolute;
  top:45%;
  transform: translateY(-50%);
  /* bottom:15%; */
  text-align: center;
  font-size:36px;
  width:100%;
  margin:0;
  padding:0 15%;
}

ion-alert-wrapper, .alert-wrapper.sc-ion-alert-md, .alert-wrapper.sc-ion-alert-ios {
  background-color: #fff;
}
@media (prefers-color-scheme: dark) {
  ion-alert-wrapper, 
  .alert-wrapper.sc-ion-alert-md, 
  .alert-wrapper.sc-ion-alert-ios {
    background-color: #111;
  }
  span.result span.timer, span.result span.timer span {
    color:#555;
  }
}

ion-modal {
  /* background-color: #fff; */
  z-index:999;
}

ion-content.QR {
  background-color: var(--ion-color-primary);
}

ion-content#QR {
  --padding-bottom:80px;
}

body.scan ion-modal {
  background-color: transparent;
}
body.scan ion-modal p.qr {
  margin-top:5px;
  font-size:15px;
}

ion-modal.QA ion-list-header ion-label h2 {
  font-weight:bold;
}
ion-menu#manuMain {
  margin-top:60px;
}
ion-menu#manuMain.ios {
  margin-top:0px;
}
ion-label span {
  font-size:12px;
}
ion-header {
  /* position:fixed; */
  z-index: 1002;
  top:0;
  box-shadow: 0 -2px 10px #000;
  background:var(--ion-color-primary);
}

ion-header ion-toolbar {
  --background:var(--ion-color-primary);
  height:80px; color:#fff;
}

ion-header.ios ion-toolbar {
  height:105px;
}


/* ion-page {
  --padding-top:80px;
  padding-top:80px;
} */
ion-content {
  --padding-top:80px;
  background-color: #ccc;
}

ion-modal ion-content {
  --padding-top:0px;
  --padding-bottom:60px;
}
ion-modal.parkPlan ion-content {
  --padding-bottom:0px;
}

ion-content.ion-padding-page {
  --padding-top:100px;
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
}
.center {
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
}

div.info_window {
  display:block;
  min-height:150px;
  text-align: center;
  color:#000;
}
.info_window_debug {
  position:absolute;
  left:8px; bottom:-5px;
  font-size:10px;
}
#compass {
  position: absolute;
  z-index:20;
  left:12px;
  top:65px;
  width:64px;
  height:64px;
}

#welcomeHeader {
  position: relative;
  /* padding-top: 60px; */
}
#welcomeHeader h1 {
  font-size: 24px;
  line-height: 140%;
  margin:0;
  text-shadow: 2px 2px 8px #000;
}
#welcomeHeader div {
  position: absolute;
  bottom:0px;
  color:#fff;
  font-weight: 600;
}
#welcomeBtn ion-col {
  padding-top: 0;
}
#welcomeBtn ion-button {
  height:120px;
  text-shadow: 1px 1px 4px #000;
}
#welcomeBtn ion-icon.zasady {
  font-size:96px;
  margin-left:10px;
}
#welcomeBtn ion-button.path_1 {
  --background: url("../theme/sciezki-i-lawki-10-1_m.jpg") var(--ion-color-primary) no-repeat;
}
#welcomeBtn ion-button.path_2 {
  --background: url("../theme/689-Mostek-w-Parku-Siemiradzkiego-1907_m.jpg") var(--ion-color-primary) no-repeat;
}
#welcomeBtn ion-button.path_3 {
  --background: url("../theme/Park-Siemiradzkiego---BN-(23)_m.jpg") var(--ion-color-primary) no-repeat;
}

/* #welcomeBtn ion-button::part(native) {
  text-align: right;
  justify-content: right;
} */

#welcomeBtn ion-button span {
  text-align: right;
  justify-content: right;
  width:100%;
}

#welcomeBtn ion-button span.nr {
  position:absolute;
  width:auto;
  top:5px;
  left:-5px;
  transform: rotate(9deg);
  font-size:64px;
  font-weight:900;
  color:saddlebrown;
  text-shadow: 1px 1px 6px #fff;
}


#intro {
  --padding-bottom: 40px;
}
ion-button.intro {
  position:fixed;
  bottom:0;
  width:100%;
}

.inner-scroll, ion-menu ion-content {
  background:#eee;
}
ion-content.trans {
  background-color:#00000000;
}

.pointName {
  position:relative;
  z-index:100;
  text-align:center;
  /* color:#fff;
  text-shadow: 1px 1px 8px #000; */
}
div.scan-box {
  display: block;
  position:absolute;
  top:50%; left:50%;
  transform: translate(-50%, -50%);
  height: 300px;
  width: 300px;

  /* border:2px solid #fff; */
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
  content: '';
}
div.scan-box div {
  position:absolute;
  width:80px;
  height:80px;
  border-color:#fff;
}
div.scan-box div:nth-child(1) {
  top:0; left:0;
  border-top:2px solid #fff;
  border-left:2px solid #fff;
}
div.scan-box div:nth-child(2) {
  top:0; right:0;
  border-top:2px solid #fff;
  border-right:2px solid #fff;
}
div.scan-box div:nth-child(3) {
  bottom:0; left:0;
  border-bottom:2px solid #fff;
  border-left:2px solid #fff;
}
div.scan-box div:nth-child(4) {
  bottom:0; right:0;
  border-bottom:2px solid #fff;
  border-right:2px solid #fff;
}

.scan-btn {
  position:fixed;
  z-index: 8;
  width:100%;
  bottom:0;
  left:0;
  background: var(--ion-color-tertiary);
}
.scan-close-btn {
  position:fixed;
  z-index: 999;
  width:50px;
  top:10px;
  right:10px;
}
.scan-close-btn.ios {
  top:30px;
}

#btn_home, .logo {
  /* position:absolute; */
  left:0; top:0; 
  width:320px; height:80px;
  --background:url("../theme/moj_park_logo_bw.png") 10px center / 80% transparent no-repeat;
  --background-hover:transparent;
  --background-activated:none;
  --ripple-color: transparent;
  --box-shadow:none;
  border:0px solid yellow;
}
#btn_menu {
  margin-top: 5px;
}
#ver {
  position:absolute;
  top:10px; right:57px;
  font-size:12px;
  opacity:0.6;
}

#timer {
  position:absolute;
  /* width:60px; */
  right:8px;
  /* left:200px; */
  top:8px;
  z-index:20;
  border:0px solid red;
}

#btn_park_map_info {
  position:absolute;
  width:40px;
  right:8px;
  top:52px;
  z-index:120;
}
#btn_park_plan {
  position:absolute;
  /* width:40px; */
  right:58px;
  top:8px;
  z-index:120;
  font-size: 12px;
}
#stoper div p {
  display:flex;
  /* flex-wrap:wrap; */
}
#stoper p {
  margin:8px;
  text-align: center;
}
#stoper p span {
  display: block;
  flex:auto;
}

#parkMapCont {
  position:absolute !important;
  top:80px;
  right:0;
  bottom:0;
  left:0;
  color:#000;
}
#parkMap {
  /* position:absolute;
  top:55px;
  right:0;
  bottom:0;
  left:0; */
  height:100%;
}
#parkMapInfo {
  position:absolute;
  top:55px; right:10px;
  z-index: 100;
  background:#ccc;
  padding: 20px;
  width:260px;
}
#getDistance {
  position:absolute;
  z-index:200;
  width:300px;
}
#mapScanBtn {
  position:absolute;
  top:95px;
  right:8px;
  text-align: left;
  font-size:12px;
  height:40px;
}
#path_point_quest {
  position:absolute;
  top:-3px;
  left:8px;
  text-align: left;
  font-size:10px;
  height:20px;
}
.pointScanBtn {
  text-align: left;
  font-size:12px;
  height:40px;
}
/* #mapScanBtn::part(native) {

} */

span.result {
  display:block;
  float:right;
  text-align: right;
  margin-top:-20px;
  font-size:64px;
  font-weight:900;
  color:var(--ion-color-tertiary)
}
span.result span {
  font-size:22px;
  font-weight:300;
}
span.result span.timer {
  display:block;
  text-align: right;
  font-size:24px;
  font-weight:700;
  color:#000;
  margin-left:20px;
}
span.result span.timer span {
  font-size:18px;
  font-weight:300;
}

@media (prefers-color-scheme: dark) {
  span.result span.timer, span.result span.timer span {
    color:#555;
  }
}


ul#results {
  padding:0 0 0 20px;
  margin:20px 0 0 0;
  list-style-type: decimal ;
}
ul#results li {
  line-height: 140%;
}

ul#results li p {
  line-height: 36px;
}

ul#results li ion-icon {
  float:left;
  margin-right:10px;
}
ul#results li ion-icon.happy {
  color:green;
}
ul#results li ion-icon.sad {
  color:red;
}


#bottomMenu-btn {
  position:absolute;
  display:block;
  top:120px;

}
#bottomMenu-toggle {
  position:absolute;
  display:block;
  width:100px; height:100px; background-color: red;
  top:180px;

}

#bottomMenu {
  position:absolute;
  bottom:0;
  height:100px;
  padding:0;
}

#footerBtn {
  position: absolute;
  width:86%;
  bottom:20px;
  padding-top:10px;
  background: rgba(255,255,255,0.6);
}

#footerBtnToggle {
  position:absolute; z-index:12;
  top:0; left:-2px;
}
#footerBtnToggle ion-icon {
  transition: all 0.4s ease-in-out;
  --color:#fff;
}
#footerBtnToggle.close ion-icon {
  /* transition: all 0.4s ease-in-out; */
  transform: rotate(-180deg);
}
#footerBtnToggle::part(native) {
  position:absolute; z-index:12;
  top:-22px; left:12px;
  width:30px; height:30px;
  padding:0;
  border-radius: 5px;
  /* background-color: pink; */
}
#footerBtnToggle ion-icon {
  /* color:#000; */
}

#footerBtn ion-button {
  /* min-width:220px; */
}
ion-icon.prev {
  transform: rotate(180deg);
}

#footerBtn ion-row.location_close ion-button {
  min-width:auto;
  font-size:12px;
}

/* #footerBtn ion-row.location_close ion-button.geolocOn::part(native) {
  --background:red;
  color:#fff;
  animation: geolocWarning 2s infinite;
} */

#footerBtn ion-row.location_close ion-button.geolocOn {
  --background:red;
  color:#fff;
  animation: geolocWarning 2s infinite linear;
}

@keyframes geolocWarning {
  /* from {--background: red;}
  to {--background: orangered } */
  
  0% {
    --background: red;
  }

  100% {
    --background: orangered;
  }
}

div.footer {
  background-color: #ccc;
  padding:5px; border-radius: 5px;
}

/* ion-modal.QA {
  padding-bottom: 50px;
} */
/* ion-modal.QA div.ion-page {
  padding-bottom: 60px;
} */

#footerModalBtn {
  position:fixed;
  bottom:0;
  left:0;
  width:100%
}
#footerModalBtn ion-button span,
#resultsFooter ion-button span {
  display:block;
  font-size:10px;
  color:#000;
}


/* News */
#news ion-card {
  background-color: #eee;
}
#news ion-item > div {
  width:100% !important;
}

#news ion-card-header {
  text-align: left;
  padding:0;
  font-weight: 600;
}
#news ion-card-header h3 {
  font-weight: 900; font-size: 18px;
}

#news ion-card-header p.data {
  font-size: 0.8em;
  line-height: 140%;
  border-bottom: 1px dotted #333;
  border-top: 1px dotted #333;
  padding: 5px 0 0 0; margin:0;
  word-wrap: break-word;
}

/* Admin */
ion-textarea.info_to_admin {
  border:1px solid #ccc;
  margin:0 0 0px 0; height:150px;
  padding:16px;
}
ion-input.email {
  border:1px solid #ccc;
  margin-bottom: 40px;;
  background:#fff;
}
textarea.opty,
textarea.info_to_admin {
  border:1px solid #ccc;
  margin:0 0 0px 0; 
  width:100%;
  height:100px;
  padding:16px;
}

ion-content.admin img {
  display:block;
  margin:20px auto;  
}

ion-button#reset {
  margin-top:20px;
}

/* Swiper */
ion-slides {
  height: 100%;
}
.swiper-container {
  --bullet-background: var(--ion-color-step-200, #cccccc);
  --bullet-background-active: var(--ion-color-primary, #fff);
  --progress-bar-background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --progress-bar-background-active: var(--ion-color-primary-shade, #fff);
  --scroll-bar-background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --scroll-bar-background-active: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}
.swiper-button-next, .swiper-button-prev {
  color: #fff;
}

.gm-style-iw {
  min-width: 180px !important; 
  min-height: 190px !important;
}

@media only screen and (max-width: 575px) and (orientation:portrait), only screen and (max-width: 575px) and (orientation:landscape) {
  #welcomeBtn ion-button.zasady {
    height:90px;
  }
  #footerBtn ion-row.location_close ion-button ion-icon {
    display:none;
  }
}


body.contrast {
  /* --background-checked:red;
  --background:#000;
  --color:yellow; */
}

body.contrast ion-content,
body.contrast ion-content#QR,
body.contrast #background-content::part(background) {
  background:#000;
  background-color:#000;
  color:yellow;
}

/* body.contrast ion-button::part(native) {
  --background:yellow;
} */

body.contrast ion-header ion-button::part(native),
body.contrast ion-header ion-menu-button::part(icon),
body.contrast ion-header ion-back-button::part(icon) {
  color:#000;
}
body.contrast.scan ion-content,
body.contrast.scan ion-content#QR {
  background:none;
}

body.contrast.scan ion-content#QR,
body.contrast ion-content#QR,
body.contrast ion-content#QR::part(scroll) {
  color:#000;
}
body.contrast ion-page .inner-scroll, 
body.contrast ion-content .inner-scroll,
body.contrast .inner-scroll {
  color:yellow;
}

body.contrast ion-content::part(scroll) {
  color:yellow;
}

@media (prefers-color-scheme: dark) {
  ion-content::part(scroll) {
    background:var(--ion-item-background);
  }
}

body.contrast #intro::part(scroll) {
  color:yellow;
}

body.contrast a {
  color:#fff;
}

body.contrast #footerModalBtn ion-button, 
body.contrast #footerModalBtn::part(native),
body.contrast #footerModalBtn::part(native) ion-button span.button-inner {
  text-shadow: none;
  color:#000;
  font-weight:900;
}

body.contrast #footerBtn ion-row.location_close ion-button.geolocOn::part(native) {
  --background:red;
  color:#fff;
  animation: geolocWarning 2s infinite linear;
}

/* @keyframes geolocWarning {
  0% {
    --background: red;
  }
  100% {
    --background: orangered;
  }
} */

body.contrast ion-button,
body.contrast ion-button::part(native) {
  text-shadow: none;
  color:#000;
  --background:yellow;
  font-weight:900;
}

body.contrast #welcomeBtn ion-button,
body.contrast #welcomeBtn::part(native) {
  height:120px;
  text-shadow: none;
  color:#000;
  font-weight:900;
  font-size:18px;
}
body.contrast #welcomeBtn ion-button span {
  font-weight:bold;
}

body.contrast #welcomeBtn ion-button span.nr {
  top: 25px;
  left: 15px;
  color: red;
  text-shadow: 1px 1px 6px #fff;
}


body.contrast #welcomeBtn ion-icon.zasady {
  font-size:96px;
  margin-left:10px;
}

body.contrast ion-header, body.contrast ion-header ion-toolbar,
body.contrast #welcomeBtn ion-button.zasady,
body.contrast #welcomeBtn ion-button.path_1,
body.contrast #welcomeBtn ion-button.path_2,
body.contrast #welcomeBtn ion-button.path_3 {
  --background: yellow;
  background: yellow;
}

body.contrast ion-button#btn_home::part(native) {
  --background: url("../theme/park_logo_contrast.png") 10px center / 80% transparent no-repeat;
}
body.contrast ion-button.danger::part(native),
body.contrast ion-button.success::part(native) {
  color:#fff;
}
body.contrast #results ion-button::part(native) {
  --background: none;
  color:yellow;
}


body.contrast ion-header ion-toolbar #ver,
body.contrast ion-header ion-toolbar #timer
 {
  color:#000;
  font-weight:bold;
}



body.contrast ion-alert .alert-wrapper.sc-ion-alert-md, body.contrast ion-alert .alert-wrapper.sc-ion-alert-io,
body.contrast ion-loading .loading-wrapper.sc-ion-loading-md, body.contrast ion-loading .loading-wrapper.sc-ion-loading-io {
  box-shadow: 2px 2px 30px #000;
  background-color: #222;
  color:yellow;
}

body.contrast ion-alert .alert-wrapper.sc-ion-alert-md button,
body.contrast ion-alert .alert-wrapper.sc-ion-alert-io button,
body.contrast ion-alert .alert-title, body.contrast ion-alert .alert-message,
body.contrast ion-alert .alert-radio-label, body.contrast ion-alert button[aria-checked='true'].sc-ion-alert-md .alert-radio-label {
  color: yellow;
}

body.contrast ion-item::part(native),
body.contrast ion-card-header,
body.contrast ion-label {
  color:yellow;
}


body.contrast textarea.opty,
body.contrast textarea.info_to_admin,
body.contrast ion-input.email,
#FCM_mobile_not {
  background-color: #222;
  border-color:#000;
  color:yellow;
}

body.contrast span.result {
  color:yellow
}
body.contrast span.result span.timer {
  color:yellow;
}
